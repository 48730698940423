<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">CREATE SCHEDULE</p>
    </div>
    <div class="dialog-content">
      <b-container fluid>
        <b-form @submit="onSubmit">
          <b-form-group label="Influencer"
                        :label-cols="2"
                        label-for="influencer"
                        horizontal>
            <multiselect class="influencer-select"
                         :class="{'invalid' : $v.form.influencer.$error}"
                         :custom-label="customName"
                         :max-height="200"
                         :multiple="false"
                         :options="influencers"
                         placeholder="Select Influencer"
                         :searchabe="true"
                         track-by="user_id"
                         v-model="form.influencer"
                         aria-describedby="influencer-sel"
            />
            <div class="error"
                 id="influencer-sel"
                 v-if="$v.form.influencer.$error">
              Influencer is required.
            </div>
          </b-form-group>

          <b-form-group label="School"
                        :label-cols="2"
                        label-for="school"
                        horizontal>
            <multiselect class="school-select"
                         label="school_name"
                         :max-height="200"
                         :multiple="false"
                         :options="schools"
                         placeholder="Filter Educator by School"
                         :searchabe="true"
                         track-by="school_id"
                         v-model="form.school"
            />
          </b-form-group>

          <b-form-group label="Educator"
                        :label-cols="2"
                        label-for="educator"
                        horizontal>
            <multiselect class="educator-select"
                         :custom-label="customName"
                         :max-height="150"
                         :multiple="false"
                         :options="educators"
                         placeholder="Select an Educator"
                         :searchable="true"
                         track-by="user_id"
                         v-model="form.educator"
            />
          </b-form-group>

          <b-form-group label="Date"
                        :label-cols="2"
                        label-for="date"
                        horizontal>
            <b-form-input id="date"
                          type="date"
                          aria-describedby="date-input"
                          @input="$v.form.date.$touch()"
                          v-model.trim="$v.form.date.$model"
                          :state="!$v.form.date.$error"
                          >
            </b-form-input>
            <b-form-invalid-feedback id="date-input"
                                    v-if="$v.form.date.$error">
              <span v-if="!$v.form.date.required">
                Date is required.
              </span>
              <span v-if="!$v.form.date.validStartDate &&
                      $v.form.date.required">
                Date is invalid.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Start Time"
                        :label-cols="2"
                        label-for="startTime"
                        horizontal>
            <b-input-group class="mb-0">
              <b-form-input id="startTime"
                            type="time"
                            aria-describedby="startTime-input"
                            @input="$v.form.startTime.$touch()"
                            v-model.trim="$v.form.startTime.$model"
                            :state="!$v.form.startTime.$error">
              </b-form-input>
              <template v-slot:append>
                <b-form-select v-model="selectedTZ"
                               :options="timezones"
                               @input="$v.selectedTZ.$touch()"
                               :state="!$v.selectedTZ.$error">
                </b-form-select>
              </template>
              <b-form-invalid-feedback id="startTime-input"
                                      v-if="$v.form.startTime.$error">
                <span v-if="!$v.form.startTime.required">
                  Start Time is required.
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
            <small id="start-time" class="form-text text-muted">
              Your Timezone:
              <b>{{ userTz | timezoneAbbreviation }}</b> |
              Influencer's Timezone:
              <span v-if="influencer">
                <b v-if="$v.selectedTZ.validTz">
                  {{ influencer.timezone | timezoneAbbreviation }}
                </b>
                <b v-else style="color: red;">
                  Not Available
                </b>
              </span>
            </small>
          </b-form-group>

          <b-form-group label="Duration"
                        :label-cols="2"
                        label-for="duration"
                        horizontal>
            <b-form-select v-model.trim="$v.form.duration.$model"
                           :options="durations"
                           size="sm"
                           aria-describedby="duration-select"
                           @input="$v.form.duration.$touch()"
                           :state="!$v.form.duration.$error">
            </b-form-select>
            <b-form-invalid-feedback id="duration-select"
                                     v-if="$v.form.duration.$error">
              <span v-if="!$v.form.duration.required">
                Duration is required.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Notes"
                        :label-cols="2"
                        label-for="notes"
                        horizontal>
            <b-form-textarea v-model="form.notes"
                             rows="3"
                             max-rows="6"
                             size="sm"
                             placeholder="Enter Notes">
            </b-form-textarea>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      type="submit"
                      variant="outline-primary"
                      size="sm"
                      :disabled="$v.$invalid">
              Submit
            </b-button>
            <b-button class="text-center ml-1"
                      type="reset"
                      variant="outline-warning"
                      size="sm">
              Reset
            </b-button>
            <b-button class="text-center ml-1 "
                      variant="outline-danger"
                      @click="$emit('close')"
                      size="sm">
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-container>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
      </loading>
    </div>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const Multiselect = () => import('vue-multiselect');

  import 'vue-loading-overlay/dist/vue-loading.css';
  import FilterMixin from '@/mixins/FilterMixin';
  import moment from 'moment-timezone';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name   : 'CreateSchedule',
    mixins : [
      FilterMixin,
    ],
    props : {
      influencers : Array,
    },
    data () {
      return {
        form : {
          influencer : null,
          school     : null,
          educator   : null,
          date       : null,
          startTime  : null,
          duration   : null,
          notes      : null,
        },
        isLoading  : false,
        userTz     : this.$store.getters['user/user'].timezone,
        selectedTZ : this.$store.getters['user/user'].timezone,
        timezones  : [ {
          value    : null,
          text     : 'Timezone',
          disabled : true,
        }, {
          value : 'America/New_York',
          text  : 'EST/EDT',
        }, {
          value : 'America/Chicago',
          text  : 'CST/CDT',
        }, {
          value : 'America/Denver',
          text  : 'MDT',
        }, {
          value : 'America/Phoenix',
          text  : 'MST No DST',
        }, {
          value : 'America/Los_Angeles',
          text  : 'PST/PDT',
        }, {
          value : 'America/Anchorage',
          text  : 'AKST/AKDT',
        }, {
          value : 'America/Adak',
          text  : 'HDT',
        }, {
          value : 'Pacific/Honolulu',
          text  : 'HST No DST',
        } ],
        schools        : [],
        educators      : [],
        validStartDate : false,
        validTz        : true,
      }
    },
    components : {
      Loading,
      Multiselect,
    },
    computed : {
      durations() {
        const durations = [{
          value    : null,
          text     : 'Select duration',
          disabled : true,
        }];
        for (let i = 3; i <= 15; i++) {
          durations.push({
            value : i,
            text  : i + ' minutes',
          });
        }

        return durations;
      },
      influencer() {
        return this.form.influencer;
      },
      school() {
        return this.form.school;
      },
      date() {
        return this.form.date;
      },
    },
    watch : {
      influencer(value) {
        this.$v.form.influencer.$touch();
        if (value)
          this.selectedTZ = value.timezone;
        else
          this.selectedTZ = this.userTz;
      },
      school(value) {
        if (value) {
          this.getEducators(value.school_id);
          this.form.educator = null;
        } else
          this.getEducators();
      },
      selectedTZ(value) {
        if (value) {
          this.validTz = true;
          if ([ 'America/New_York', 'America/Chicago', 'America/Denver',
                'America/Phoenix', 'America/Los_Angeles', 'America/Anchorage',
                'America/Adak', 'Pacific/Honolulu' ].indexOf(value) === -1)
            this.validTz = false;

          if (this.form.date && this.form.startTime)
            this.checkStartDate();
        }
      },
      date(value) {
        if (value)
          this.checkStartDate();
      },
    },
    methods : {

      /**
       * Custom Label by Name for Multiselect
       */
      customName({ firstname, lastname }) {
        return `${firstname} ${lastname}`;
      },

      /**
       * Get School List
       */
      getSchoolList() {
        this.$http.get('api/school/all')
          .then(response => {
            this.schools = response.data;
          })
          .catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          });
      },

      /**
       * Get School Educators
       * @param schoolId
       */
      getEducators(schoolId = '') {
        this.educators = [];
        const parameter = (schoolId) ? { schoolId : parseInt(schoolId) } : {};

        this.$http.get('api/school/educators', {
          params : parameter,
        }).then(response => {
          this.educators = response.data;
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Create Schedule
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;

        // set default time zone
        moment.tz.setDefault(this.form.influencer.timezone);

        const startTime = this.form.date + ' ' + this.form.startTime;
        const endTime = moment(startTime).add(this.form.duration, 'minutes')
          .format('YYYY-MM-DD HH:mm:ss');

        const params = {
          startTime    : startTime,
          endTime      : endTime,
          influencerId : this.form.influencer.user_id,
          timezone     : this.form.influencer.timezone,
        };

        if (this.form.notes)
          params.notes = this.form.notes;

        this.$http.post('api/broadcast/slot', params)
          .then(response => {
            if (this.form.educator) {
              // set slot as reserved
              this.reserveSchedule(response.data.broadcastSlotId);
            } else {
              this.$parent.$emit('create-schedule-successful');
              this.$emit('close');
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success!',
                text  : 'Created schedule successfully!',
              });
            }
          }).catch(err => {
            let errTxt = 'Oops! Something went wrong!';
            if (err.response.data.msg)
              errTxt = err.response.data.msg;

            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : errTxt,
            });
          }).finally(() => {
            this.isLoading = false;
          });
      },

      /**
       * Reserve Schedule
       * @param id
       */
      reserveSchedule(id) {
        this.$http.put('api/broadcast/slot/reserve', {
          status           : 'reserved',
          educatorId       : this.form.educator.user_id,
          broadcastSlotId  : id,
          educatorTimezone : this.form.educator.timezone,
        }).then(() => {
          this.$parent.$emit('create-schedule-successful');
          this.$emit('close');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Reserved schedule successfully!',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.isLoading = false;
        });
      },

      /**
       * Check if Start Date is valid
       */
      checkStartDate() {
        const date = new Date();
        const currDateTz = moment.tz(date, this.selectedTZ)
          .format('YYYY-MM-DD');
        const startDate = this.form.date;

        if ( startDate > currDateTz )
          this.validStartDate = true; // Start Date is valid
        else
          this.validStartDate = false; // Start Date is invalid
      },
    },
    mounted() {
      this.getSchoolList();
      this.getEducators();
    },
    validations : {
      form : {
        influencer : { required },
        date       : {
          required,
          validStartDate() {
            return this.validStartDate;
          },
        },
        startTime : { required },
        duration  : { required },
        school    : {},
        educator  : {},
        notes     : {},
      },
      selectedTZ : {
        required,
        validTz() {
          return this.validTz;
        } },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/create-schedule";
</style>